/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { ChatButton } from "../components/typeform"

import "../styles/layout.scss"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <>
      {children}

      <ChatButton />
      <Footer />
    </>
  )
}
export default Layout
