import React, { useState } from "react"
import { ReactTypeformEmbed } from "react-typeform-embed"
import { motion, AnimatePresence, animate } from "framer-motion"

const PopUp = ({ children }) => {
  const [openTypeform, setOpenTypeform] = useState(false)
  return (
    <>
      <div onClick={() => setOpenTypeform(true)} style={{ cursor: "pointer" }}>
        {children}
      </div>
      <AnimatePresence>
        {openTypeform && (
          <motion.div
            className="popup-cnt"
            variants={Parent}
            initial="initial"
            animate="animate"
            exit={{ opacity: 0 }}
          >
            <motion.div className="dark-bg" onClick={() => setOpenTypeform(false)} variants={childFadeUp} />
            <motion.div className="popup" variants={childFadeUp}>
              <div className="content">
                <ReactTypeformEmbed url="https://hg0i68yf21q.typeform.com/to/K47kVXte" />
              </div>
            </motion.div>
            <div
              className="exit-button"
              onClick={() => setOpenTypeform(false)}
              style={{ color: "white" }}
            >
              +
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default PopUp

export const ChatButton = () => {
  const [openChat, setOpenChat] = useState(false)
  return (
    <>
      <div className="chat-button" onClick={() => setOpenChat(!openChat)}>
        <svg width="24" height="24" fill="none" viewBox="0 0 84 83">
          <rect width="84" height="56" fill="#fff" rx="8"></rect>
          <path fill="#fff" d="M43 50h41v33L43 50z" opacity=".7"></path>
        </svg>
      </div>
      <AnimatePresence>
        {openChat && (
          <motion.div
            className="chat-box-cnt"
            variants={chatParent}
            initial="initial"
            animate="animate"
            exit={{ opacity: 0 }}
          >
            <motion.div
              variants={childFadeUp}
              className="exit"
              onClick={() => setOpenChat(false)}
            >
              +
            </motion.div>
            <div className="chat-box">
              <ReactTypeformEmbed url="https://hg0i68yf21q.typeform.com/c/K47kVXte" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

const chatParent = {
  initial: { opacity: 0, height: 0 },
  animate: {
    opacity: 1,
    height: "650px",
    transition: {
      delayChildren: 0.3,
      type: "spring",
      mass: 0.2,
      duration: 0.005,
    },
  },
}

const childFadeUp = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
}

const Parent = {
  initial: {},
  animate: { transition: { delayChildren: 0.3, staggerChildren: 0.1 } },
}
