import React from "react"

const Footer = () => {
  return (
    <div className="footer-cnt">
      <div className="margin">
        <footer>
          <div className="details">
            
            <div>ATNZ, 277 Broadway Newmarket, Auckland <a href="tel:0800 692 869">0800 692 869</a> 8.30am – 5pm</div>
          </div>

          <SocialLinks />
        </footer>
      </div>
    </div>
  )
}

export default Footer

const SocialLinks = () => {
  return (
    <ul className="social">
      <li>
        <a
          href="https://www.linkedin.com/company/apprentice-training-new-zealand/"
          target="_blank"
          class="linkedin"
        >
          <img
            src="https://atnz.org.nz/themes/competenz/images/social/linkedin.svg"
            alt="LinkedIn"
          />
        </a>
      </li>

      <li>
        <a
          href="https://www.facebook.com/ATNZapprentice/ "
          target="_blank"
          class="facebook"
        >
          <img
            src="https://atnz.org.nz/themes/competenz/images/social/facebook.svg"
            alt="Facebook"
          />
        </a>
      </li>

      <li>
        <a
          href="https://www.instagram.com/atnzapprentice "
          target="_blank"
          class="instagram"
        >
          <img
            src="https://atnz.org.nz/themes/competenz/images/social/instagram.svg"
            alt="Instagram"
            class=""
          />
        </a>
      </li>

      <li>
        <a
          href="https://www.youtube.com/channel/UChpDQSWP3iReWaJjkH_AkjA/videos"
          target="_blank"
          class="youtube"
        >
          <img
            src="https://atnz.org.nz/themes/competenz/images/social/youtube.svg"
            alt="Youtube"
            class=""
          />
        </a>
      </li>
    </ul>
  )
}
